// Define desired color palettes. (see: https://material.io/guidelines/style/color.html)
$primary: "indigo";
$accent: "blue-grey";
$warn: "amber";

// If desired, import the palettes and modify to your liking.
@import "./styles/color-palette";
//$color-teal: map-merge($color-teal, ('contrast':  $light-contrast-color));
//$color-light-green: map-merge($color-light-green, ('contrast':  $light-contrast-color));

// Import the rest of ember-paper.
// @import './styles/ember-paper';

body {
	margin: 0;
	padding: 0;
	min-width: 800px;
	background: #000;
	font: 13px/1.5 'Helvetica Neue', Arial, 'Liberation Sans', FreeSans, sans-serif;
}

body.blank {
	background-color: #333;
	font: 13px/1.5 'Helvetica Neue', Arial, 'Liberation Sans', FreeSans, sans-serif;
	text-align: center;

	h1 { color: #ccc; text-align: center; padding-top: 200px; }

	h2 {
		color: #ddd;
		line-height: 3em;
		margin-bottom: 100px;
	}

	a {
		color: #eee;
	}
}

#homepage {
	background: #000;

	.header {
		width: 100%;
		padding: 48px 0 0 0;
		height: 113px;
		background: #000 url(./images/netphase-hdr-bg.jpg) no-repeat top center;

	    .mojo {
	        color: #fff;
	        float: right;
	        width: 350px;
	        height: 26px;
	        font-size: 1em;
	        line-height: 1em;
	        text-transform: uppercase;
	        text-align: right;
	    }
	}

	.content {
		background: #f1f1f1;
		min-height: 600px;
	}

	.lead-content {
		background: #fff;
		padding: 40px 0 30px;
		border-bottom: 1px solid #aeaeae;
		min-height: 250px;

	    img {
	        float: right;
	        padding: 8px;
	        margin-left: 20px;
	        width: 300px;
	        border: 1px solid #efefef;
	        margin-top: 0px;
	    }
	}

	.container-12 {
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}



	.main-content {
		padding: 20px 0;
	}

	.footer {
		font: normal normal normal 11px Tahoma, Arial, sans-serif;
		text-transform: uppercase;
		background: #000 url(./images/footer-bg.jpg) no-repeat top center;
		color: #fff;
		padding: 12px 0;
		height: 29px;
	}

	.copyright {
		text-align: right;
		padding-right: 60px;
	}

	.group:after {
	  content: "";
	  display: table;
	  clear: both;
	}

	.what-we-do {
	    a {
	        color: black;
	        font-weight: bold;
	    }
	    h4 {
	        margin: 0 0 5px 0;
	    }
	    img {
	        float: left;
	        margin-right: 20px;
	    }
	    div {
	        float: left;
	        width: 50%;
	        margin-bottom: 20px;
	    }
	    p {
	        margin: 0 10px;
	    }
	}

	.contact a {
	    color: white;
	    float: left;
	    margin-left: 40px;
	}
}
